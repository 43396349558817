body {
    margin-top: 70px;
}

.scroll {
    opacity: 0.3;
    background-color: black;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    border: none;


}

.scroll:hover {
    opacity: 1;
}


.arrow-up::before {
    content: "\e113";
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -7px;
}

.btn.scrollToTop {
    position: fixed;
    bottom: 10px;
    right: 10px;
}